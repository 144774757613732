import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import vuetify from './plugins/vuetify'
import router from './router'
import GlobalConfig, { loadConfig } from '@/GlobalSettings'

Vue.config.productionTip = false


import VueDragscroll from 'vue-dragscroll'
import IKUtils from "innerken-js-utils";
import {hillo} from "hillo";
import {getBaseAndUrlForDeviceId} from "@/service/api";
Vue.use(VueDragscroll)

export const realDeviceId = IKUtils.getQueryString('Base')
async function initial () {
  await loadConfig()
  if (realDeviceId) {
    const realUrl = (await getBaseAndUrlForDeviceId(realDeviceId)).url
    hillo.initial(realUrl + '/PHP/')
  }

  i18n.locale=GlobalConfig.lang.toLowerCase()
  console.log("GlobalConfig", GlobalConfig)
  new Vue({
    i18n,
    vuetify,
    router,
    render: h => h(App)
  }).$mount('#app')

}

initial()

