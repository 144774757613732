<template>
    <v-app>
        <v-app-bar
                v-if="$vuetify.breakpoint.mobile||menu"
                app
                dense
                elevation="0"
        >
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

            <v-spacer></v-spacer>
            <v-menu
                    bottom
                    left
                    offset-y
                    origin="top right"
                    transition="scale-transition"
            >
                <template v-slot:activator="{ attrs, on }">
                    <v-btn
                            class="ml-2"
                            min-width="0"
                            text
                            v-bind="attrs"
                            v-on="on"
                    >
                        <v-icon>mdi-web</v-icon>
                    </v-btn>
                </template>

                <v-list
                        :tile="false"
                        nav
                >
                    <v-list-item
                            v-for="(n, i) in item"
                            :key="i"
                            @click="changeLanguage(n)"
                    >
                        <v-list-item-title
                                v-text="n"
                        />
                    </v-list-item>
                </v-list>
            </v-menu>
            <!-- 设置 -->
            <div class="">
                <v-menu
                        offset-y
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-width="300"
                        :max-height="650"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs"
                               v-on="on"
                               text
                               x-large
                        >
                            <v-icon>mdi-cog-outline</v-icon>
                        </v-btn>
                    </template>
                    <v-card flat tile>
                        <v-list>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Aaden Fast Pick</v-list-item-title>
                                    <v-list-item-subtitle>
                                        Version {{ version }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>

                        <v-divider></v-divider>
                        <v-list dense>
                            <template v-for="key in NeededKeys">
                                <v-list-item :key="'config'+key">
                                    <v-list-item-title>{{ key }}</v-list-item-title>
                                    <template v-if="typeof Config[key]==='boolean'">
                                        <v-list-item-action>
                                            <v-switch v-model="Config[key]" color="purple"></v-switch>
                                        </v-list-item-action>
                                    </template>
                                    <template v-else>
                                        <v-list-item-action>
                                            <v-text-field v-model="Config[key]" color="purple"></v-text-field>
                                        </v-list-item-action>
                                    </template>
                                </v-list-item>
                            </template>
                        </v-list>

                        <v-card-actions class="pb-12">
                            <v-btn text @click="hardReload">Clear</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn text @click="menu = false">Cancel</v-btn>
                            <v-btn text @click="useCurrentConfig">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-menu>
            </div>
            <!-- 设置 -->
        </v-app-bar>

        <!-- 左上角抽屉 -->
        <v-navigation-drawer
                v-model="drawer"
                absolute
                width="50vw"
                temporary
                app
        >
            <div style="height: 48px;">
                <v-icon class="ma-1" x-large @click="drawer =!drawer">
                    mdi-close-circle-outline
                </v-icon>
            </div>
            <div>
                <div class="mobilText d-flex justify-center align-center" style="height: 50px;">
                    {{ $t('Abgeholt') }}
                </div>
            </div>

            <div style="overflow: auto; ">
                <div v-for="o in taken" :key="o.id" class="d-flex justify-center">
                    <div style="width: 80%">
                        <v-card :key="o.id"
                                color="white"
                                class="mb-2 text-center"
                                @click="changeFinishOrderStatus(o)"
                        >
                            <div style="color: black; font-size: xx-large">
                <span v-if="!Config.showTableName">#{{
                    o.dailySerial ? o.dailySerial : o.name
                    }}</span>
                                <span v-else>#{{ o.tableId }}</span>
                            </div>


                        </v-card>

                    </div>

                </div>
            </div>
        </v-navigation-drawer>
        <v-main app>
            <Home @refresh="reloadOrders" :orders="orders"/>
        </v-main>

    </v-app>
</template>

<script>

import {backFinishOrder, loadOrders, resetOrder} from '@/service/api'
import IKUtils from 'innerken-js-utils'
import GlobalConfig, {changeLanguage, hardReload, NeededKeys, useCurrentConfig} from '@/GlobalSettings'
import Home from '@/views/Home'
import {version} from '../package.json'

export default {
    name: 'App',
    components: {Home},
    data: () => ({
        drawer: false,
        navi: null,
        orderSn: null,
        orders: [],
        version: version,
        menu: null,
        NeededKeys,
        Config: GlobalConfig,
        item: [
            'ZH',
            'EN',
            'DE',
        ],
    }),
    computed: {
        taken() {
            return this.orders.filter(o => parseInt(o.pickStatus) === 2).reverse()
        }
    },
    methods: {
      async changeFinishOrderStatus(item) {
        await backFinishOrder(item)
      },
        changeLanguage,
        pickUpAll() {
            IKUtils.showConfirm('Stellen Sie alle Orders als Fertige ein?')
            this.orders.forEach(o => {
                return parseInt(o.pickStatus) === 1 ? resetOrder(o) : console.log("false")
            })
        },
        async reloadOrders() {
          try {
            this.orders = await loadOrders()
          } catch (e) {
            this.orders = []
          }
        },
        useCurrentConfig,
        hardReload,
    },
    async mounted() {
        await this.reloadOrders()
        const t = setInterval(this.reloadOrders, 3000)
        this.$once('hook:beforeDestroy', () => {
            clearInterval(t)
        })
    },
}
</script>

<style>
.mobilText {
    font-size: 24px;
    height: 40px;
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-content: center;*/
}

div {
    font-family: 'Noto Sans SC', 'Noto Sans', sans-serif !important;
}
</style>

