import { hillo } from 'hillo'
import GlobalSettings from '@/GlobalSettings'

export async function loadOrders () {
  return (await hillo.get('Orders.php?op=getTodayPickUpOrder')).content.map(t => {
    t.name = t.name ?? 'Table not exists'
    t.dailySerial = parseInt(t.name.toUpperCase().replace("W", "")) % GlobalSettings.maxCount + (parseInt(GlobalSettings.dailyStart ?? 0))
    return t
  })
}

export async function pushOrder (order) {
  if (order.pickStatus < 2) {
    return (await changeOrderStatus(order.id, parseInt(order.pickStatus) + 1))
  }

}

export async function backFinishOrder(order){
  return (await changeOrderStatus(order.id, 0))
}


export async function resetOrder (order) {
  return (await changeOrderStatus(order.id, 2))
}


export async function changeOrderStatus (id, status) {
  return (await hillo.post('Orders.php?op=changeOrderPickUpStatus', {
    orderId: id,
    status: status
  })).content
}

export async function showPickUpBannerImages () {
  return await hillo.get('Restaurant.php?op=showGiraffeBannerImage').content
}

export async function getRestaurantInfo () {
  return await hillo.get('Restaurant.php?op=view')
}

export const cloudUrl = 'https://cloud5.api.aaden.io'

export async function getBaseAndUrlForDeviceId (deviceId) {
  const url = (await findDeviceByDeviceId(deviceId))
      ?.baseUrl ?? getNgrokUrl(deviceId)
  return {
    deviceId,
    url
  }
}

export function getNgrokUrl (deviceId) {
  return `${location.protocol}//ik${deviceId.padStart(4, '0')}.ngrok.aaden.io`
}

export async function findDeviceByDeviceId (deviceId) {
  try {
    return (await hillo.jsonPost(cloudUrl + '/virtualDevice/search', { deviceId: deviceId })).data?.[0]
  } catch (e) {
    console.log(e)
    return null
  }
}

