import { loadBaseConfig } from 'aaden-base-model/lib/Models/GlobalSettings'

const defaultConfig = require('@/assets/config.json')
export let NeededKeys = []
let GlobalConfig = Object.assign({}, defaultConfig)

export const OrderState = [
  'Preparing',
  'Ready',
  'Finish'
]

export function isWeb () {
  return !process.env.IS_WEB
}

export function reload () {
  if (!isWeb()) {
    const { ipcRenderer } = require('electron')
    ipcRenderer.send('reload')
  } else {
    window.location.reload(true)
  }
}

export async function loadConfig () {
  GlobalConfig = Object.assign(GlobalConfig, await loadBaseConfig(defaultConfig))
  NeededKeys = GlobalConfig.neededKeys

  window.Config = GlobalConfig
}
export function changeLanguage (l) {
  GlobalConfig.updateSettings('lang', l)
  window.location.reload(true)
}

export function useCurrentConfig () {
  GlobalConfig.useCurrentConfig(GlobalConfig)
}

export function hardReload () {
  GlobalConfig.hardReload()
}

export function setDeviceId (id) {
  GlobalConfig.updateSettings('DeviceId', id)
  reload()
}

window.useCurrentConfig = useCurrentConfig
window.hardReload = hardReload
window.setDeviceId = setDeviceId


export default GlobalConfig
