<template>
    <div elevation="0" style="overflow: hidden;">
        <template v-if="$vuetify.breakpoint.mobile">
            <v-card
                    tile
                    style="display: grid;grid-template-columns:repeat(2,1fr);overflow: hidden;"
            >
                <div>
                    <div class="d-flex ma-1">
                        <span class="text-h5">{{ $t('Preparing') }}</span>
                    </div>
                    <div
                            style="max-height: calc(100vh - 112px); overflow: auto;display: grid;grid-template-columns: repeat(2,1fr)">
                        <div v-for="o in making.filter(t=>t.consumeTypeStatusId>5)"
                             class="ma-1 d-flex justify-center align-center grey lighten-4 py-4"
                             :key="o.id"
                             @click="pushOrder(o)"
                        >

                            <div class="text-h4">
                                #{{
                                o.dailySerial ? o.dailySerial : o.name
                                }}
                            </div>

                        </div>
                    </div>
                </div>
                <div>
                    <div class="d-flex ma-1">
                        <span class="text-h5">{{ $t('Ready') }}</span>
                    </div>
                    <div
                            style="overflow: auto; max-height: calc(100vh - 108px);display: grid;grid-template-columns: repeat(2,1fr)">
                        <div class="ma-1 py-4 amber lighten-4 d-flex align-center justify-center"
                             v-for="o in togo"
                             :key="o.id"
                             @click="pushOrder(o)"
                        >
                            <div>
                                <div class="text-h4">
                                    <span v-if="!Config.showTableName">#{{
                                        o.dailySerial ? o.dailySerial : o.name
                                        }}</span>
                                    <span v-else>{{ o.tableId }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-card>
        </template>
        <template v-else>
            <div style="position: relative;overflow: hidden">
                <div v-if="imgUrl" :style="{right:'36px'}" style="position: fixed;bottom:36px;overflow: visible">
                    <v-img contain width="240" :src="imgUrl"></v-img>
                </div>
                <div class="pa-4" style="width: 100%;height: 50vh;overflow: hidden" :key="i.status" v-for="i in info">
                    <div class="text-h3 font-weight-black"
                         style="width: fit-content;font-size: 4rem;border-bottom: 2px dashed black">
                        {{ $t(i.header) }}
                    </div>
                    <div class="mt-4">
                        <div
                                style="grid-template-columns: repeat(4,minmax(0,1fr));display: grid;overflow: hidden;grid-gap: 12px;width: 100%">
                            <v-card
                                    elevation="0"
                                    tile
                                    dark
                                    :color="!i.status?'amber lighten-4 black--text':'black'"
                                    v-dragscroll
                                    class="pa-2 align-center d-flex justify-center font-weight-black"
                                    v-for="o in i.status?making:togo"
                                    :key="o.id"
                                    @click.stop="pushOrder(o)"
                                    style=" font-size: 4.2rem"
                            >
                                #{{
                                o.dailySerial ? o.dailySerial : o.name
                                }}
                            </v-card>
                        </div>
                    </div>
                </div>

            </div>
        </template>
    </div>
</template>

<script>
import {getRestaurantInfo, pushOrder} from '@/service/api'
import GlobalConfig from '@/GlobalSettings'
import {dragscroll} from 'vue-dragscroll'

export default {
    directives: {
        dragscroll
    },
    name: 'Home',
    data: () => ({
        mobile: false,
        imgUrl: null,
        Config: GlobalConfig,
        tab: null,
        notice: "",
        row: GlobalConfig.row,
        info: [
            {header: 'Preparing', status: true},
            {header: 'Ready', status: false}
        ]

    }),
    props: {
        orders: {
            type: Array
        },
        dark: {
            type: Boolean
        }

    },
    computed: {
        making() {
            return this.orders.filter(o => parseInt(o.pickStatus) === 0).filter(t => t.consumeTypeStatusId > 5)
        },
        togo() {
            return this.orders.filter(o => parseInt(o.pickStatus) === 1)
        }

    },
    mounted() {
        this.getImages()
        this.mobile = this.$vuetify.breakpoint.mobile
    },
    methods: {
        async pushOrder(order) {
            await pushOrder(order)
            this.$emit('refresh')
        },
        async getImages() {

            const res = await getRestaurantInfo()
            console.log(this.res)
            const resInfo = res.content[0]

            // get image Url
            if (resInfo.buffetLogo) {
                this.imgUrl = GlobalConfig.Protocol + GlobalConfig.Base + '/' + resInfo.buffetLogo
            }


        }

        // Config.mobile
    }


}
</script>

<style scoped>


.readyItem:first-child {
    text-decoration: underline;
    font-size: 6rem;
    background: #e87727;
    color: white;
    line-height: 96px;
    padding: 16px 4px;
    display: flex;
    justify-content: center;
    align-content: center;
    border-radius: 16px;
    width: 100%;
}

.readyItem {
    width: 50%;
    margin-top: 16px;
    user-select: none;
    cursor: default;
    /*height: 57.5px;*/
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    font-size: 5rem;
    /*letter-spacing: -0.96px;*/
    text-align: center;
    color: #e87727;
}

.mobilText {
    font-size: 24px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-content: center;
}

.notice {
    font-size: xxx-large;
    padding-left: 40px;
    /*-webkit-animation:mymove 5s infinite;*/
}

.notice1 {
    font-size: 30px;
    margin-left: 100%;
    width: 100%;
    animation: mymove 35s linear infinite;
    animation-fill-mode: forwards;
    /*-webkit-animation:mymove 5s infinite;*/
}

@keyframes mymove {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-2500px);
    }
}

/*mobilText*/
/*App.vue setting*/
</style>
